"use strict";/*******************************
// Footer Directions links open native map app on mobile
*******************************/function mapsSelector(location){if(/* if we're on iOS, open in Apple Maps */navigator.platform.indexOf("iPhone")!=-1||navigator.platform.indexOf("iPad")!=-1||navigator.platform.indexOf("iPod")!=-1)window.open("maps://maps.google.com/maps?daddr="+location+"&amp;ll=");else/* else use Google */window.open("https://maps.google.com/maps?daddr="+location+"&amp;ll=");}jQuery.noConflict();(function($){$(document).ready(function(){// andherewego.exe
/*******************************
// new client download tracking
*******************************/$("a.ncd-download").click(function(){gtag('event','click',{'event_category':'outbound','event_label':'NCD','value':'download'});});$("a.ned-download").click(function(){gtag('event','click',{'event_category':'outbound','event_label':'NED','value':'download'});});/*******************************
// navigation lock
*******************************/$(document).scroll(function(event){var scroll=$(document).scrollTop();$('header.header').toggleClass('stuck',//add 'stuck' class on scroll.
scroll>=1);$('#slider_spacer').toggleClass('navbar-stuck',//add 'stuck' class on scroll.
scroll>=1);$('.content').toggleClass('navbar-stuck',//add 'stuck' class on scroll.
scroll>=1);});/*******************************
// mobile subnavigation
*******************************/$('.opensub').click(function(event){$(this).toggleClass('open');$('#sidenav').slideToggle('fast');if($('#sidenav').hasClass('open')){$('#sidenav').removeClass('open');}else{$('#sidenav').addClass('open');}});$('#toggle-inner').click(function(){$('.widget_nav_menu').slideToggle('300','swing');$('.widget_nav_menu').toggleClass('open');$('.subnav-toggle').toggleClass('active');});$('html').click(function(){if($('.widget_nav_menu').hasClass('open')){$('.widget_nav_menu').slideToggle('300','swing');$('.widget_nav_menu').toggleClass('open');$('.subnav-toggle').toggleClass('active');}});$('.subnav-toggle').click(function(event){event.stopPropagation();});// Search Slide
$('#search-icon').click(function(){$(".search-form").toggle("slide");$('.search-field').focus();$('#social_connect').toggleClass("open");if($('#social_connect').hasClass('open')){$('#social_connect').removeClass("shrink");$('#social_connect').addClass("small-12 medium-shrink");}else{$('#social_connect').removeClass("small-12");$('#social_connect').addClass("shrink medium-shrink");}});// Blog Sidebar
$('h4.widgettitle').click(function(event){$(this).toggleClass('open');$(this).next('ul').slideToggle('fast');});$('h4.sidebar-search-title').click(function(event){$(this).toggleClass('open');$(this).next('.sidebar-search-form').slideToggle('fast');Foundation.reInit('equalizer');$('.sidebar-search-field').focus();});/* ///// Services ///// */// add class to rows depending on if item has description
$('.serv-desc').closest('.service_row').addClass('serv_desc-row');$('.serv_desc-row').prev('.service_row').addClass('has_desc');// add class to sub sub-cat row
$('.serv-title-sub-subcat').closest('.service_row').addClass('serv-sub-subcat');$('.serv-sub-subcat').nextUntil('.serv-sub-subcat, .im-service-wrapper').find('.serv-title').addClass('serv-indent');// accordion descriptions
$('.has_desc').click(function(event){if($(this).hasClass('open')){}else{$('.has_desc.open').removeClass('open');$('.serv_desc-row.is-active').slideToggle('300','swing');$('.serv_desc-row.is-active').removeClass('is-active');}if($(this).hasClass('open')){$(this).removeClass('open');}else{$(this).addClass('open');}if($(this).next('.serv_desc-row').hasClass('is-active')){$(this).next('.serv_desc-row').removeClass('is-active');}else{$(this).next('.serv_desc-row').addClass('is-active');}$(this).next('.serv_desc-row').slideToggle('300','swing');});// alt bg on rows, since CSS selectors can't do it
var count=0;$('.im_services tr:not(.serv_desc-row)').each(function(){if(++count%2==1)$(this).addClass('altrow');// ++count vs. count++ selects even instead of odd rows
$('.im_services tr.altrow').next('.serv_desc-row').css('background-color','#e5e4e2');// apply to altrow descriptions too
});// mobile accordion
$('.accordion').on('down.zf.accordion',function(event){console.log('Accordion done');$('html, body').animate({scrollTop:$('.accordion-item.is-active').offset().top-120},1000);});// Footer Hours
$('.hour_toggle').click(function(){var $hours=$(this).parent().find('.hours_drop');$($hours).slideToggle('300','swing');$(this).toggleClass('active');$(this).parentsUntil('btm_location').siblings().children().find('.hours_drop').slideUp();$(this).parentsUntil('btm_location').siblings().children().find('.hour_toggle').removeClass('active');return false;});$(".hours_drop").on("click",function(event){event.stopPropagation();});$(document).on("click",function(){$('.hours_drop').slideUp();$('.hour_toggle').removeClass('active');});/*******************************
// instafeed
*******************************/$('#beginfeed').slick({infinite:true,slidesToShow:4,slidesToScroll:4,autoplay:true,autoplaySpeed:4000,prevArrow:'<button type="button" class="slick-prev"><img src="/wp-content/themes/Imaginal-Child/assets/images/nav-arrow-black.svg" alt="Previous" /></button>',nextArrow:'<button type="button" class="slick-next"><img src="/wp-content/themes/Imaginal-Child/assets/images/nav-arrow-black.svg" alt="Next" /></button>',responsive:[{breakpoint:1024,settings:{slidesToShow:4,slidesToScroll:2}},{breakpoint:640,settings:{slidesToShow:2,slidesToScroll:2}}]});/*******************************
// locations
*******************************/$('.location_gallery').slick({infinite:true,autoplay:true,autoplaySpeed:5000,prevArrow:'<button type="button" class="slick-prev"><img src="/wp-content/themes/Imaginal-Child/assets/images/nav-arrow-black.svg" alt="Previous" /></button>',nextArrow:'<button type="button" class="slick-next"><img src="/wp-content/themes/Imaginal-Child/assets/images/nav-arrow-black.svg" alt="Next" /></button>'});// Blog Icon Slideout
// Facebook
var $fb_initial_width=$('.share-icon.fb').find('.icon-filler').width();$(".share-icon.fb").hover(function(){var $text_width=$(this).find('.share-text').width();var $full_width=$fb_initial_width+5;$full_width+=$text_width;$(this).find(".icon-filler").css("width",$full_width);},function(){$(this).find(".icon-filler").css("width",$fb_initial_width);});// Twitter
var $tw_initial_width=$('.share-icon.tw').find('.icon-filler').width();$(".share-icon.tw").hover(function(){var $text_width=$(this).find('.share-text').width();var $full_width=$tw_initial_width+5;$full_width+=$text_width;$(this).find(".icon-filler").css("width",$full_width);},function(){$(this).find(".icon-filler").css("width",$tw_initial_width);});// Mail
var $mail_initial_width=$('.share-icon.mail').find('.icon-filler').width();$(".share-icon.mail").hover(function(){var $text_width=$(this).find('.share-text').width();var $full_width=$mail_initial_width+5;$full_width+=$text_width;$(this).find(".icon-filler").css("width",$full_width);},function(){$(this).find(".icon-filler").css("width",$mail_initial_width);});// Jobs Filtering
var $grid=$('.jobsgrid').isotope({itemSelector:'.job_post_wrapper',layoutMode:'fitRows',filter:'.all'});// filter functions
var filterFns={// no filter functions in use
};// bind filter button click
$('.filters-button-group').on('click','.job_filter',function(){var filterValue=$(this).attr('data-filter');// use filterFn if matches value
filterValue=filterFns[filterValue]||filterValue;$grid.isotope({filter:filterValue});});// change active class on buttons
$('.filters-button-group').each(function(i,buttonGroup){var $buttonGroup=$(buttonGroup);$buttonGroup.on('click','.job_filter',function(){$buttonGroup.find('.active').removeClass('active');$(this).addClass('active');});});// end it all
});})(jQuery);